import { useEffect, useState } from 'react'
import SellButton from "react-sellapp";
import { Dialog } from '@headlessui/react'
import { Outlet, Link, useParams } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ShieldCheckIcon, CheckIcon } from '@heroicons/react/20/solid'
import YouTube from 'react-youtube';


const topnavigation = [
  { name: 'Cheats', to: '/cheats' },
  { name: 'TOS', to: '/tos' },
  { name: 'Status', to: '/status' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Discord', to: '/discord' },
]

const opts = {
  height: '400',
  width: '600',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const VideoPlayer = ({ videoId }) => {
  return <YouTube videoId={videoId} opts={opts} />;
};


const reviews = { average: 6, totalCount: 1123 }

const products = [
  {
    id: 168608,
    name: 'EFT Private',
    category: 'EFT',
    to: '/Product/168608',
    price: '$7.99',
    imageSrc: 'sBCQAEwZLuY',
    url: "https://reportedhacks.sell.app/product/eft-private-undetected?store=reportedhacks",
    desc:
      `SEMI RAGE CHEAT  | WORKS ON ALL WINDOWS 10 | AMD / INTEL CPU | BUILT IN HWID SPOOFER | BEST FOR TASKING AND CLOSET CHEATING`,
  },
  {
    id: 168609,
    name: 'Plus EFT',
    category: 'EFT',
    to: '/Product/168609',
    price: '$7.49',
    imageSrc: 'qEhqvJXHjEA',
    url: "https://reportedhacks.sell.app/product/plus-eft-undetected?store=reportedhacks",
    desc:
      `BEST LEGIT CHEAT | WORKS ON ALL WINDOWS 10 SOME WINDOWS 11 | AMD / INTEL CPU | BUILT IN HWID SPOOFER
    `,
    status: 'Undetected'
  }, // DO ABS FUCKER
  {
    id: 168610,
    name: 'ABS EFT',
    category: 'EFT',
    to: '/Product/168610',
    price: '$9.99',
    imageSrc: '',
    url: "https://reportedhacks.sell.app/product/abs-eft-Undetected",
    desc:
      `BEST SEMIRAGE | WORKS ON ALL WINDOWS 10 SOME WINDOWS 11 | AMD / INTEL CPU | BUILT IN HWID SPOOFER
    `,
    status: 'Undetected'
  },
  {
    id: 181122,
    name: 'Rust Private',
    category: 'Rust',
    to: '/Product/181122',
    price: '$39.99',
    imageSrc: 'Pz3b4WJ6ODQ',
    url: "https://reportedhacks.sell.app/product/rust-private-undetected?store=reportedhacks",
    desc:
      `SEMI RAGE CHEAT | WORKS WITH WINDOWS 10 AND 11 | AMD / INTEL CPU `,
    status: 'Undetected'
  },
  {
    id: 175962,
    name: 'Reported R6 full',
    category: 'R6',
    to: '/Product/175962',
    price: '$5.99',
    url: "https://bestcheatsworldwide.sell.app/product/reported-r6full",
    imageSrc: '',
    desc:
      `LEGIT CHEAT | WORKS ON WINDOWS 10 AND 11 | AMD / INTEL CPU | NVIDIA GPU`,
    status: 'Undetected'
  },
  {
    id: 214414,
    name: 'Speedtail R6',
    category: 'EFT',
    to: '/Product/214414',
    price: '$24.99',
    imageSrc: 'dueiO19kYco',
    url: "https://bestcheatsworldwide.sell.app/product/speedtail-r6",
    desc:
      `BEST R6 CHEAT | WORKS ON ALL WINDOWS 10 AND 11 | AMD / INTEL CPU | UNDETECTED 10 MONTHS
    `,
    status: 'Undetected'
  },
  {
    id: 215342,
    name: 'Reported R6 lite',
    category: 'EFT',
    to: '/Product/215342',
    price: '$5.99',
    imageSrc: '2_7iH6UtfIg',
    url: "https://bestcheatsworldwide.sell.app/product/reported-r6",
    desc:
      `BEST LEGIT CHEAT | WORKS ON ALL WINDOWS 10 AND WINDOWS 11 | AMD / INTEL CPU | NVIDIA GPUS
    `,
    status: 'Undetected'
  },
  {
    id: 170334,
    name: 'Reported Apex',
    category: 'Apex',
    to: '/Product/170334',
    price: '$4.99',
    imageSrc: 'tL8Gnk5aoQk',
    url: "https://reportedhacks.sell.app/product/reported-apex-undetected?store=reportedhacks",
    desc:
      `SEMI RAGE CHEAT | WORKS WINDOWS 10 AND 11 | AMD / INTEL CPU |`,
    status: 'Undetected'
  },
  {
    id: 170335,
    name: 'Reported Apex Glow',
    category: 'Apex',
    to: '/Product/170335',
    price: '$2.49',
    url: "https://reportedhacks.sell.app/product/reported-apex-glow-undetected?store=reportedhacks",
    desc:
      `LEGIT CHEAT | WORKS WITH WINDOWS 10 AND 11| AMD / INTEL CPU |`,
    status: 'Undetected'
  },
  {
    id: 194668,
    name: 'Fecurity COD',
    category: 'COD',
    to: '/Product/194668',
    price: '$4.99',
    imageSrc: 'I4y025sBsKI',
    url: "https://reportedhacks.sell.app/product/fecurity-cod-undetected?store=reportedhacks",
    desc:
      `SEMI RAGE CHEAT | WORKS WITH WINDOWS 10 AND 11| AMD / INTEL CPU `,
    status: 'Undetected'
  },
  {
    id: 172325,
    name: 'CS2 pro',
    category: 'CS',
    to: '/Product/172325',
    price: '11.99',
    imageSrc: 'N9tE0alqD4U',
    url: "https://reportedhacks.sell.app/product/cs2-pro-undetected?store=reportedhacks",
    desc:
      `SEMI RAGE CHEAT | WORKS WITH WINDOWS 10 AND 11 | AMD / INTEL CPU |`,
    status: 'Undetected'
  },
  {
    id: 170333,
    name: 'Reported Spoofer',
    category: 'Spoofer',
    to: '/Product/170333',
    price: '$9.99',
    url: "https://reportedhacks.sell.app/product/reported-spoofer-undetected?store=reportedhacks",
    desc:
      `MULTI GAME HWID SPOOFER| WORKS WITH WINDOIWS 10 AND 11 | AMD / INTEL CPU |`,
    status: 'Undetected'
  }
  
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  let { id } = useParams();

  var productDisplay = {}
  products.map((product) => {
    if(parseInt(product.id) === parseInt(id)) {
      productDisplay = product
    }
  })

  return (
    <>
    <header>
      <link href="https://cdn.sell.app/embed/style.css" rel="stylesheet" />
    </header>
    <div>
      <div className="isolate bg-black">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="px-6 pt-6 lg:px-8">
          <nav className="flex items-center justify-between" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Reported Cheats</span>
                <img className="h-12" src="/reported.png" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {topnavigation.map((item) => (
                <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white hover:text-fuchsia-500">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-black px-6 py-6 lg:hidden">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Reported Cheats</span>
                  <img className="h-12" src="/reported.png" alt="" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {topnavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6">
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
            <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                {/* Product details */}
                <div className="lg:max-w-lg lg:self-end">

                <div className="mt-4">
                    <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{productDisplay.name}</h1>
                </div>

                <section aria-labelledby="information-heading" className="mt-4">
                    <h2 id="information-heading" className="sr-only">
                    Product information
                    </h2>

                    <div className="flex items-center">
                    <p className="text-lg text-white sm:text-xl">Starting At</p>

                    <div className="ml-4 border-l border-gray-300 pl-4">
                        <p className="text-lg text-white sm:text-xl">{productDisplay.price}</p>
                    </div>
                    </div>

                    <div className="mt-4 space-y-6">
                    <p className="text-base text-white">{productDisplay.desc}</p>
                    </div>

                    <div className="mt-6 flex items-center">
                    <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <p className="ml-2 text-sm text-white">Available</p>
                    </div>
                </section>
                </div>

                {/* productDisplay image */}
                <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
                <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
                <img src={productDisplay.imageSrc} alt={productDisplay.desc} className="h-full w-full object-cover object-center" />
                <VideoPlayer videoId={productDisplay.imageSrc} />
                </div>
                </div>

                {/* productDisplay form */}
                <div className="lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
                <section aria-labelledby="options-heading">
                    <div className="mt-10">
                        {/* <SellButton store="31527" product={productDisplay.id} darkmode={true} className="flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-500 py-3 px-8 text-base font-medium text-white hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-fuchsia-700 focus:ring-offset-2 focus:ring-offset-gray-50"/> */}
                        <a href={productDisplay.url} target='_blank'>
                          <button 
                            className="flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-500 py-3 px-8 text-base font-medium text-white hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-fuchsia-700 focus:ring-offset-2 focus:ring-offset-gray-50"
                            // data-sell-store="31527" 
                            // data-sell-product={productDisplay.id} 
                            // data-sell-darkmode="true"
                          >
                            Buy now
                          </button>
                        </a>
                    </div>
                    <div className="mt-6 text-center">
                        <Link to="#" className="group inline-flex text-base font-medium">
                        <ShieldCheckIcon
                            className="mr-2 h-6 w-6 flex-shrink-0 text-white group-hover:text-gray-300"
                            aria-hidden="true"
                        />
                        <span className="text-gray-500 hover:text-gray-700">Secured Payments</span>
                        </Link>
                    </div>
              </section>
            </div>
          </div>
        </div>
      <footer className="bg-black">
        <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-300">
              &copy; © 2024 Reported Cheats, Inc. All rights reserved.
            </p>
          </div>
        </div>
    </footer>
    </div>
    </>
  )
}
